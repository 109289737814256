import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetTreatmentPlan } from '../../hooks';
import { TreatmentPlanContext } from '../../context/treatmentPlan/treatmentPlan.context';
import { Footer, Header } from '../../components';
import {
  InhaledSteroids,
  SupportTools,
  Introduction,
  SideEffects,
  Symbicort,
  HowToUse,
} from './components';
import { MainBanner } from './components/MainBanner/MainBanner';

export const TreatmentPlans = () => {
  const { treatmentPlan, setTreatmentPlan } = useContext(TreatmentPlanContext);

  const { pathname } = useLocation();

  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname);

  const { themePage } = useSetTreatmentPlan(cleanPathName);

  //SET THE TREATMENT PLAN TEXT TO DISPLAY BASED ON THE PATHNAME
  useEffect(() => {
    setTreatmentPlan(cleanPathName);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  return (
    <>
      <Header />

      <MainBanner themePage={themePage} />

      <Introduction themePage={themePage} treatmentPlan={treatmentPlan} />

      <Symbicort treatmentPlan={treatmentPlan} />

      <HowToUse treatmentPlan={treatmentPlan} />

      <InhaledSteroids treatmentPlan={treatmentPlan} themePage={themePage} />

      <SideEffects treatmentPlan={treatmentPlan} />

      <SupportTools treatmentPlan={treatmentPlan} />

      <Footer />
    </>
  );
};

export default TreatmentPlans;
