import React, { FC, useEffect, useState } from 'react';
import styles from './HowToUse.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetImageForLanguage, useSetTreatmentPlan } from '../../../../hooks';
import { VideoPlayer } from '../../../../components';
import { useLocation } from 'react-router-dom';
import { getUrl } from 'aws-amplify/storage';

type HowToUseProps = {
  treatmentPlan: any;
};
export const HowToUse: FC<HowToUseProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    i18n: { language },
  } = useTranslation();

  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname);

  const { howToUseCardList } = useSetTreatmentPlan(cleanPathName);

  useEffect(() => {
    async function getSignedUrl() {
      const link = await getUrl({
        path: videoPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToVideo(link);
    }

    getSignedUrl();
  }, []);

  //GET VIDEO DETAILS
  const videoPath = t(`turbohalerVideo.videoPath`);
  const [linkToVideo, setLinkToVideo] = useState<any>(null);

  //CONFIGURE VIDEO PLAYER
  const playerRef = React.useRef(null);
  const videoPlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: useGetImageForLanguage(
      language,
      'myFirstInhaler/videoThumbnail.png'
    ),
    posterText: t(`turbohalerVideo.videoText`),
    sources: [
      {
        src: linkToVideo?.url.href,
        type: 'video/mp4',
      },
    ],
  };
  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  return (
    <div className={styles.howToUse} data-testid="HowToUse">
      <section id="howToUse" className={styles.howToUseContainer}>
        <small> {t(`${treatmentPlan}.howToUsePreTitle`)} </small>

        <h1 className={styles.title}>{t(`${treatmentPlan}.howToUseTitle`)}</h1>

        {linkToVideo && (
          <div className={styles.videoPlayer}>
            <VideoPlayer
              options={videoPlayerOptions}
              onReady={handlePlayerReady}
            />
          </div>
        )}

        <div className={styles.flex}>
          <div className={styles.paragraph}>
            <h3
              dangerouslySetInnerHTML={{
                __html: t(`${treatmentPlan}.howToUseInfoTitle`),
              }}
            ></h3>

            <p>{t(`${treatmentPlan}.howToUseInfoParagraph1`)}</p>

            <p>{t(`${treatmentPlan}.howToUseInfoParagraph2`)}</p>
          </div>
          <div className={styles.card}>
            <h4
              dangerouslySetInnerHTML={{
                __html: t(`${treatmentPlan}.howToUseCardTitle`),
              }}
            ></h4>
            <hr />

            <ul>
              {howToUseCardList.map((item) => (
                <li key={item}>{item} </li>
              ))}
            </ul>
          </div>
        </div>
        <h4 className={styles.leftBorder}>
          {t(`${treatmentPlan}.howToUseFooter`)}
        </h4>
      </section>
    </div>
  );
};
