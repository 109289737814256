import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../Anchor/Anchor';
import { BurgerMenu } from './components/BurgerMenu';
import logo from '../../assets/icons/a2b_blue_logo.png';
import styles from './Header.module.scss';

export const Header = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);

  };

  return (
    <div className={styles.header} data-testid="Header">
      {/* ADVERSE EVENT  */}
      <div className={styles.adverseEventContainer}>
        <span data-testid="adverseEvent" >{t('header.adverseEvent')}</span>
        <a
          href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/eg/en/amp-form.html"
          target="_blank"
          data-testid="reportAdverseEvent"
          className={styles.buttonReportAdverse}
        >
          {t('header.buttonAdverseEvent')}
        </a>

        <select
          className={styles.changeLanguage}
          value={i18n.language.slice(0,2)}
          onChange={handleChangeLanguage}
        >
          <option value="en">EN</option>
          <option value="es">ES</option>
        </select>
      </div>
      <div className={styles.headerContainer}>
        {/* ============= LOGO ============== */}
        <Link to={'/'} relative="path" className={styles.logoContainer}>
          <img src={logo} alt="air 2 breathe logo" className={styles.logo} />
        </Link>
        {/* =========== BURGER MENU ============= */}
        {pathname == '/' ? null : <BurgerMenu />}
        {/* =========== NAVBAR ============= */}
        <div
          className={styles.anchors}
          style={pathname == '/' ? {} : { display: 'flex' }}
        >
          <Anchor />
        </div>
      </div>
    </div>
  );
};
