import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ETreatmentPlan } from '../models/treatmentPlans.enum';
import pdf from '../assets/icons/pdf.png';
import video from '../assets/icons/video.png';
import checks from '../assets/icons/checks.png';
import { getUrl } from 'aws-amplify/storage';

export const useSetTreatmentPlan = (treatmentPlan: string) => {
  const { t } = useTranslation();
  let referencesList: string[] = [];
  let howToUseCardList: string[] = [];
  let sideEffectTabs: any[] = [];
  let supportToolsCards: any[] = [];
  let themePage = { mainImage: '', background: '', backgroundColor: '' };

  const [actionPlan, setaActionPlan] = useState<string>();
  const [patientLeaflet, setPatientLeaflet] = useState<string>();
  const paths = {
    asthmaActionPlan: t('asthmaActionPlan.actionPlanPath'),
    patientInformationLeaflet: t('patientInformationLeaflet.leafletPath'),
  };
  useEffect(() => {
    const fetchActionPlanLink = async () => {
      const asthmaLink = await getUrl({
        path: paths.asthmaActionPlan,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setaActionPlan(asthmaLink.url.href);
    };
    const fetchPatientLeafletLink = async () => {
      const leaftletLink = await getUrl({
        path: paths.patientInformationLeaflet,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setPatientLeaflet(leaftletLink.url.href);
    };
    fetchActionPlanLink();
    fetchPatientLeafletLink();
  }, []);

  const getSideEffectTabs = (baseKey: string) => [
    {
      id: 'commonSideEffects',
      title: t(`${baseKey}.commonSideEffectsSubTitle`),
      list: t(`${baseKey}.commonSideEffectsLi`, {
        returnObjects: true,
      }) as string[],
    },
    {
      id: 'unCommonSideEffects',
      title: t(`${baseKey}.uncommonSideEffectsSubTitle`),
      list: t(`${baseKey}.uncommonSideEffectsLi`, {
        returnObjects: true,
      }) as string[],
    },
    {
      id: 'rareSideEffects',
      title: t(`${baseKey}.rareSideEffectsSubTitle`),
      list: t(`${baseKey}.rareSideEffectsLi`, {
        returnObjects: true,
      }) as string[],
    },
    {
      id: 'veryRareSideEffects',
      title: t(`${baseKey}.veryRareSideEffectsSubTitle`),
      list: t(`${baseKey}.veryRareSideEffectsLi`, {
        returnObjects: true,
      }) as string[],
    },
  ];

  const getSupportToolsCards = (baseKey: string) => [
    {
      icon: checks,
      iconText: 'PDF',
      title: t(`${baseKey}.asthmaActionPlan`),
      description: t(`${baseKey}.asthmaActionPlanDescription`),
      linkLabel: t(`${baseKey}.asthmaActionPlanLinkLabel`),
      asset: actionPlan,
    },
    {
      icon: video,
      iconText: 'video',
      title: t(`${baseKey}.symbicortVideo`),
      description: t(`${baseKey}.symbicortVideoDescription`),
      linkLabel: t(`${baseKey}.symbicortVideoLinkLabel`),
    },
    {
      icon: pdf,
      iconText: 'PDF',
      title: t(`${baseKey}.patientInformationLeaflet`),
      description: t(`${baseKey}.patientInformationLeafletDescription`),
      linkLabel: t(`${baseKey}.patientInformationLeafletLinkLabel`),
      asset: patientLeaflet,
    },
  ];

  const treatmentPlanConfig: any = {
    [ETreatmentPlan.MY_FIRST_INHALER]: {
      themePage: {
        background: '#FFDA37',
        mainImage: 'people/yellowPersonNoBg.png',
        backgroundColor: '#FFEFA9',
      },
      baseKey: 'my-first-inhaler',
    },
    [ETreatmentPlan.ONLY_RELIEVER]: {
      themePage: {
        background: '#FE92BF',
        mainImage: 'people/purplePersonNoBg.png',
        backgroundColor: '#FEBBD7',
      },
      baseKey: 'only-reliever',
    },
    [ETreatmentPlan.RELIEVER_AND_PREVENTER]: {
      themePage: {
        background: '#B50D24',
        mainImage: 'people/redPersonNoBg.png',
        backgroundColor: '#FFE4E5',
      },
      baseKey: 'reliever-and-preventer',
    },
    [ETreatmentPlan.SOMETHING_ELSE]: {
      themePage: {
        background: '#9EED79',
        mainImage: 'people/greenPersonNoBg.png',
        backgroundColor: '#DAFFC9',
      },
      baseKey: 'something-else',
    },
  };

  const config = treatmentPlanConfig[treatmentPlan];

  if (config) {
    themePage = config.themePage;
    const baseKey = config.baseKey;

    referencesList = t(`${baseKey}.referencesList`, {
      returnObjects: true,
    }) as string[];
    howToUseCardList = t(`${baseKey}.howToUseCardList`, {
      returnObjects: true,
    }) as string[];
    sideEffectTabs = getSideEffectTabs(baseKey);
    supportToolsCards = getSupportToolsCards(baseKey);
  }

  return {
    referencesList,
    howToUseCardList,
    sideEffectTabs,
    supportToolsCards,
    themePage,
  };
};
